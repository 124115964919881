import { createStore } from 'vuex';
import axiosInstance from '../connections/AxiosInstance.js';
import Collection from '../models/Collection.js';


const store = createStore({
    state: function(){
        return {
           jwt: '',
           AppData: {},
           AppDataInformation: {},
           userPermissions: null,
           currentUserId: null,
           UserProfile: {},

           ModelReference: {},
           RecordsBuffer: {},
           timeout: null
        }
    },
    mutations: {
        checkToken(state) {
            var token = sessionStorage.getItem('jwttoken')
            if (token){
                state.jwt = token;
                state.UserProfile = JSON.parse(sessionStorage.getItem('UserProfile'));
            }
        },
        userLogin(state, data){
            state.jwt = data.token;
            delete data.token;
            state.UserProfile = data;
            sessionStorage.setItem('jwttoken', state.jwt);
            sessionStorage.setItem('UserProfile', JSON.stringify(state.UserProfile));
        },
        setPermissions(state, permissionsObject){
            state.userPermissions = permissionsObject;
        },
        logOut(state){
            state.jwt = '';
            state.UserProfile = {};
            sessionStorage.removeItem('jwttoken');
            sessionStorage.removeItem('UserProfile');
        },
        setupCollection (state, model) {
            if (!Object.hasOwnProperty.call(state.AppData, model.name)){
                // console.log("Store set up a new collection");
                state.AppData[model.name] = new Collection(model, {isAppData: true});
            }
        },
        setupInformation (state, {model, type, prop, spec}) {
            if(!Object.hasOwnProperty.call(state.AppDataInformation, model.name)){
                state.AppDataInformation[model.name] = {};
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name], type)){
                state.AppDataInformation[model.name][type] = {};
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name][type], prop)){
                state.AppDataInformation[model.name][type][prop] = {};
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name][type][prop], spec)){
                state.AppDataInformation[model.name][type][prop][spec] = [];
            }
        },


        setInformation(state, {model, type, prop, spec, data}) {
            this.commit('setupCollection', model);
            this.commit('setupInformation', {model, type, prop, spec});
            state.AppDataInformation[model.name][type][prop][spec].length = 0;
            state.AppDataInformation[model.name][type][prop][spec].push(...data);
        },



        //adds a record request to the queue
        addRecord(state, {model, id}){

            // console.log("Adding record to queue");
            if (!model.source){
                // console.trace();
                console.log(`!!Tried to get erroneous record on ${model.name} ${id}`);
                return;
            }
            if (!Object.hasOwnProperty.call(state.ModelReference, model.name)){
                state.ModelReference[model.name] = model;
            }
            if (!Object.hasOwnProperty.call(state.RecordsBuffer, model.name)){
                state.RecordsBuffer[model.name] = [];
            }
            
            //not already in the buffer, not loaded, and not fetching already
            if (state.RecordsBuffer[model.name].indexOf(id) === -1
                && state.AppData[model.name][id]._loaded === false
                && state.AppData[model.name][id]._fetching === false
            ){
                // console.log(`Added to buffer ${model.name} ${id}`);
                state.RecordsBuffer[model.name].push(id);

                clearTimeout(state.timeout);
                state.timeout = setTimeout(() => {
                    axiosInstance.handleRecordsBuffer();
                    state.timeout = null;
                }, 100);
            }
        }

    },
    getters: {
        isLoggedIn: function(state){
            return !!state.jwt.length;
        },
        modelReady: (state) => (model) => {
            return Object.hasOwnProperty.call(state.AppData, model.name);
        },
        hasInformation: (state) => ({model, type, prop, spec}) => {
            if(!Object.hasOwnProperty.call(state.AppDataInformation, model.name)){
                return false;
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name], type)){
                return false;
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name][type], prop)){
                return false;
            }
            if(!Object.hasOwnProperty.call(state.AppDataInformation[model.name][type][prop], spec)){
                return false;
            }
            return true;
        },
    },
    actions: {
        //setup a collection
        ensure (context, model){
            // console.log("ensure: ", model.name);
            context.commit('setupCollection', model);
            return context.state.AppData[model.name]._loader;
        },
        //setup a single record
        prepare (context, {model, id}){
            // console.log("prepare: ", model.name, id);
            context.dispatch('ensure', model);
            if (!Object.hasOwnProperty.call(context.state.AppData[model.name], id)){
                // console.log(`Store had to prepare ${model.name} ${id}`);
                context.state.AppData[model.name].add(id);
            }
        },
        //populate the collection and return the loader promise
        populate (context, {model, data}) {
            // console.log(`Store populating collection ${model.name}`, data);
            context.dispatch('ensure', model);
            context.state.AppData[model.name].populate(data);
            return context.state.AppData[model.name]._loader;
        },


        //get one
        get (context, {model, id}){
            // console.log(`Store getting one ${model.name} ${id}`);
            context.dispatch('prepare', {model, id});
            axiosInstance.get(model, id);
            return context.state.AppData[model.name][id]._loader;
        },
        getAll (context, model){
            // console.log(`Store getting all ${model.name}`);
            context.dispatch('ensure', model);
            axiosInstance.getAll(model);
            return context.state.AppData[model.name]._loader;
        },
        getSeries (context, model){
            // console.log(`Store getting series ${model.name}`);
            context.dispatch('ensure', model);
            return axiosInstance.getSeries(model);
        },

        
        getInformation (context, {model, type, prop, spec}){
            context.commit('setupCollection', model);
            if (!context.getters.hasInformation({model, type, prop, spec})){
                context.commit('setupInformation', {model, type, prop, spec});
                return axiosInstance({
                    type: 'get',
                    url: `${model.source}/Inform/${type}/${prop}/${spec}`
                }).then((response) => {
                    context.state.AppDataInformation[model.name][type][prop][spec].push(...response.data);
                }, (error) => {
                    console.log(error);
                });
            }
            return Promise.resolve();
        }

    }
});

export default store;