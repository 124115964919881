import axios from 'axios';
import store from '../store/store.js';



const axiosInstance = axios.create({
    // baseURL: 'https://localhost:44358/'
    baseURL: 'https://fast-lane-api-qa.azurewebsites.net/'
});

window.activeRequests = window.activeRequests || 0;


axiosInstance.interceptors.request.use(
    (config) => {
        store.commit('checkToken');
        const token = store.state.jwt;
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        window.activeRequests++;
        return config; 
    }, 
    (error) => Promise.reject(error)
);
axiosInstance.interceptors.response.use(
    (response) => {
        window.activeRequests--;
        return response; 
    }, 
    (error) => Promise.reject(error)
);

//gets data for a particular record in a model
axiosInstance.get = function(model, id) {

    return axiosInstance({
        method: 'get', 
        url: `${model.source}/${id}`,
    }).then((response) => {
        store.dispatch('populate', {model: model, data: [response.data]});
        return response;
    }, (error) => {
        console.log(error);
        throw error;
    });

}

//gets all available data for a model
axiosInstance.getAll = function(model) {
    return axiosInstance({
        method: 'get', 
        url: model.source,
    })
}

//gets the ids of all available data for a model
axiosInstance.getSeries = function(model) {
    return axiosInstance({
        method: 'get', 
        url: `${model.source}/Series`,
    });
}

//gets all relatedModel for a model with id
axiosInstance.getRelated = function(relatedModel, model, id){
    return axiosInstance({
        method: 'get',
        url: `${relatedModel.source}/${model.source}/${id}`
    }).then((response) => {
        console.log(`Got all ${relatedModel.name} for ${model.name} id ${id}`);
        store.dispatch('populate', {model: relatedModel, data: response.data});
        return response;
    }, (error) => {
        console.log(error);
        throw error;
    });
}

//handles records request buffer
axiosInstance.handleRecordsBuffer = function(model, buffer){
    console.log(`List request for ${buffer.length} of ${model.name}`);
    return axiosInstance({
        method: 'post',
        url: `${model.source}/List`,
        data: buffer
    });
}

export default axiosInstance;