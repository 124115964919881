import Model from './Model.js';

import VendorModel from './VendorModel.js';

class ShopLocationModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('name', String, true);
        this.prop('street', String, true);
        this.prop('street2', String, false);
        this.prop('city', String, true);
        this.prop('state', String, true);
        this.prop('zipCode', String, true);
        this.prop('latitude', Number, false);
        this.prop('longitude', Number, false);
        this.prop('description', String, false);
        this.prop('ownerId', VendorModel, true);

        this.populate(values, config);

    }
    static name = 'ShopLocationModel';
    static source = 'ShopLocation';
}
window[Symbol.for(ShopLocationModel.name)] = ShopLocationModel;

export default ShopLocationModel;