<template>
    <div class="main-search">
        <div class="form-group search-text">
            <label>
                <i class="fas fa-search"></i>
                <span class="placeholder">Search</span>
                <input type="text" placeholder="Search" v-model="searchTerm" @focus="openSearch" />
                <a class="input-clear" @click="searchTerm = ''"><i class="fas fa-times"></i></a>
            </label>
        </div>
        <div class="search-results" @click="closeSearch">
            <!-- <router-link to="/search/advanced">Advanced search...</router-link> -->
            <ul v-for="(category, key) in filteredResults" :key="key">
                <li class="category-header"><router-link :to="{path: category.link, query: categoryQuery(key)}">{{category.title}}</router-link></li>
                <li v-for="result in category.results" :key="result"><router-link :to="result.link" v-html="(formatTitle(result.title))"></router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
const results = {
    'users': {
        title: 'Users',
        link: '/users', 
        match: 'full-name',
        results: [
            {
                title: 'Freer Hall',
                link: '/users/freer-hall',
            },
            {
                title: 'Calvin Hobbs',
                link: '/users/calvin-hobbs'
            },
            {
                title: 'Bob Dole',
                link: '/users/bob-dole'
            }
        ]
    },
    'category-one': {
        title: 'Category One',
        link: '/', 
        match: 'name',
        results: [
            {
                title: 'Plane All',
                link: '/'
            },
            {
                title: 'Train All',
                link: '/'
            },
            {
                title: 'Automobile All',
                link: '/'
            }
        ]
    },
    'category-two': {
        title: 'Category Two',
        link: '/', 
        match: 'name',
        results: [
            {
                title: 'Repute All',
                link: '/'
            },
            {
                title: 'Refute All',
                link: '/'
            },
            {
                title: 'Reputable All',
                link: '/'
            }
        ]
    }
}


    export default {
        data: function(){
            return {
                searchTerm : "",
            }
        },

        computed: {
            filteredResults: function(){
                const trimmedSearchTerm = this.searchTerm.toLowerCase().trim();

                if (trimmedSearchTerm === ""){
                    return {};
                }
                let filteredResultsBuilder = {};
                for (var category in results){
                    if (Object.prototype.hasOwnProperty.call(results, category)){

                        let filteredResultsCategoryBuilder = {
                            title: results[category].title,
                            link: results[category].link,
                            match: results[category].match,
                            results: []
                        };
                        
                        filteredResultsCategoryBuilder.results = results[category].results.filter(result => result.title.toLowerCase().indexOf(trimmedSearchTerm) !== -1);
                        
                        if (filteredResultsCategoryBuilder.results.length){
                            filteredResultsBuilder[category] = filteredResultsCategoryBuilder;
                        }
                    }
                }

                return filteredResultsBuilder;
            }
        },
        watch: {
        },

        props: ["equipmentList"],

        methods: {
            formatTitle: function(title){
                if (this.searchTerm.trim() !== ""){
                    const trimmedSearchTerm = this.searchTerm.toLowerCase().trim();
                    let start = title.toLowerCase().indexOf(trimmedSearchTerm);
                    let end = start + trimmedSearchTerm.length;
                    return title.slice(0, start) + "<span>" + title.slice(start, end) + "</span>" + title.slice(end);
                }
                return title;
            },
            closeSearch: function () {
                this.$emit("close-search")
            },
            openSearch: function () {
                this.$emit("open-search")
            },
            categoryQuery: function(category){
                let categoryQueryBuilder = {};
                categoryQueryBuilder[results[category].match] = this.searchTerm;
                return categoryQueryBuilder;
            }
        }
    }
</script>



<style lang="scss">
@import "../assets/scss/variables.scss";


.search-open{
    .main-search {
        .search-text{
            background: nth($brands, 10);

            &::after{
                opacity: 1;
            }

            .fa-search{
                color: $brand;

            }
            input{
                border-color: nth($grays, 5);
                background: $white;
                opacity: 1;
                cursor: text;
                width: 100%;

                &:focus {
                    ~ .input-clear{
                        opacity: 1 !important;
                    }
                }
            }

            &:hover{
                .input-clear{
                    opacity: 1 !important;
                }
            }
        }
        .search-results{
            opacity: 1;
            right: 0;
            min-height: 600px;
        }
    }
}
.header:hover, 
.header.open{
    &:not(.search-open){
        .main-search{
            .placeholder{
                opacity: 1;
                width: 100%;
            }
        }
    }
}

.main-search {
    .search-text{
        &::after{
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            width: 4px;
            border-radius: 2px;
            background: $brand;
            opacity: 0;
        }

        label{
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover{
                background: nth($brands, 10);
            }
        }

        .fa-search{
            display: inline-block;
            width: 35px;
            text-align: center;
            color: nth($grays, 6);
            margin-left: $space-wide;
            transition: $transition-standard;
            line-height: 50px;
            flex: 0 0 auto;

        }
        
        .placeholder{
            opacity: 0;
            width: 0;
            transition: opacity 0.15s ease-in;
            color: nth($grays, 6);
            flex: 0 0 auto;
            margin-bottom: 0;
        }

        input{
            opacity: 0;
            width: 0;
            border-color: rgba(255,255,255,0);
            transition: $transition-standard, width 0s;
            margin-left: -5px;
            margin-right: $space-wide;
            background: rgba(255,255,255,0);
            cursor: pointer;

            &::placeholder{
                color: nth($grays, 6);
            }
        }

        .input-clear{
            opacity: 0 !important;
            right: $space-wide;
            margin-top: -14px;
        }

    }


    .search-results{
        background: $white;
        margin: 0;
        padding: 0; 
        position: absolute;
        top: 50px;
        right: 100%;
        bottom: 0;
        left: 0;
        z-index: 100;
        overflow: hidden;
        opacity: 0;
        transition: right 0s, opacity 0.15s ease-in;

        ul {
            list-style: none;
            padding: $space-standard 0;
            
            + ul{
                border-top: 1px solid nth($grays, 5);
            }

            .category-header{
                a{
                    font-weight: $font-bold;
                }
            }

        }
        a{
                    font-family: $font-secondary;
                    color: $font-color;
                    font-size: 16px;
                    font-weight: $font-regular;
                    text-decoration: none;
                    line-height: 45px;
                    padding: 0 $space-standard;
                    margin: 0;
                    display: block;
                    transition: $transition-standard;
                    overflow: hidden;
                    white-space: nowrap;

                    
                    &:hover {
                        background: nth($brands, 10);
                        color: $brand;
                    }

                    span {
                        text-decoration: underline;
                    }
                }
    }
}

</style>