import Model from './Model.js';


class MakeModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('name', String, true);

        this.populate(values, config);

    }
    static name = 'MakeModel';
    static source = 'Make';
}
window[Symbol.for(MakeModel.name)] = MakeModel;

export default MakeModel;