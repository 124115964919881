import Enum from './Enum';
import store from '../store/store.js';
import Collection from './Collection';
import { ref } from 'vue';
import Email from './Email';

var AppData = store.state.AppData;

class Model {
    constructor(){
        store.dispatch('ensure', this);

        Object.defineProperties(this, {
            //accessible, read-only prop types
            'typeof' : {
                value: {},
                enumerable: false
            },
            //underlying property values
            'valueof': {
                value: {},
                writable: true,
                enumerable: false
            },
            //property is nullable
            'nullable': {
                value: {},
                enumerable: false
            },
            //popualte function
            'populate': {
                enumerable: false,
                writable: true
            },
            //prop function
            'prop': {
                enumerable: false,
                writable: true
            },

            //if this model was ever populated (temp holding object versus object populated from server)
            '_loaded' : {
                enumberable: false, 
                set: (value) => {
                    loaded.value = value;
                },
                get: () => {
                    return loaded.value;
                } 
            },
            //promise to resolve when populated
            '_loader' :{
                enumerable: false,
                writable: true
            },

            //flag model for validation
            '_edit': {
                value: false,
                writable: true,
                enumerable: false
            },
            //property-level value is valid
            '_valid': {
                value: {},
                writable: true,
                enumerable: false
            },
            //property-level errors
            '_error': {
                value: {},
                writable: true,
                enumerable: false
            },

            //hold property-level validations
            '__validation': {
                value: {},
                writable: true,
                enumerable: false
            },


            //model-level error
            '__error' : {
                enumberable: false,
                writable: true
            },
            //actively getting this record
            '_fetching' : {
                enumberable: false,
                writable: true
            }
        });


        var loaded = ref(false);
        this.__error = false;
        this._fetching = false;

        var resolver;
        const $this = this;
        this._loader = new Promise((resolve) => {
            resolver = resolve;
        }).then((result) => {
            loaded.value = true;
            $this._fetching = false;
            $this.__error = false;
            return result;
        }); 

        //Shorthand setter:
        //Populates all values from an object with props 
        //Where this Model contains those props

        this.populate = function(values, config = {}){
            config;
            Object.freeze(this.typeof);
            Object.freeze(this.nullable);

            if (!values){
                return;
            }
            
            for (var prop in values){
                //checking is actual property in values
                if (Object.prototype.hasOwnProperty.call(values, prop)){

                    //if this prop exists already in our model
                    if (Object.prototype.hasOwnProperty.call(this, prop)){

                        if (this.typeof[prop] === Date){
                            this[prop] = new Date(values[prop]);
                        } else if(this.typeof[prop] === Number){
                            this[prop] = (+values[prop]);
                        } else if (
                            Array.isArray(this.typeof[prop]) && this.typeof[prop][0] !== undefined && this.typeof[prop][0].prototype instanceof Model 
                            || this.typeof[prop] !== undefined && this.typeof[prop].prototype instanceof Model
                        ) {
                            //!!CHECK IF SETTING TWICE Sometimes? like someone called populate twice.
                            if (values[prop] === null){
                                this.valueof[prop] = [];
                            } else {
                                this.valueof[prop] = values[prop];
                            }
                        } else {
                            this[prop] = values[prop];
                        }
                        
    
                        // if (config.deep == true){
                        //     this[prop];
                        // }
                    } 
                }
            }
            resolver();
            return this;
        }

        //Property declaration:
        //Add a property to this model
        this.prop = function(prop, type, nullable, validation){
            if (type === undefined){
                throw 'Model properties must have a type';
            } 

            //FOREIGN MODEL COLLECTION
            //An array of foreign key references to another model type class
            if (Array.isArray(type) && type[0].prototype instanceof Model){      

                const subType = type[0];
                store.dispatch('ensure', subType);

                //"get" will return this collection with values that point to AppDate records
                var objectReferences = new Collection(subType);

                Object.defineProperty(this, prop, {
                    set: function(value){
                        
                        this.__validate(this, prop, value);

                        if (value === null){
                            //if setting null, remove all references
                            Object.keys(objectReferences).forEach(key => delete objectReferences[key]);

                        } else if (Array.isArray(value)){
                            //!!this gets overriden if there are direct assignments to the collection
                            //!!probably unavoidable, catch 22 can't watch and not watch the collection
                            for (var i in value){
                                this.valueof[prop].push(value[i]);
                            }

                            if (value.length === 0){
                                //if we've set this and it's empty, it's okay to show it loaded
                                objectReferences._initialState = true;
                            }

                        } else {

                            //we are instead directly assigning objects 
                            objectReferences.populate(this.valueof[prop]);
                        }

                    },
                    get: function(){
                        if (Array.isArray(this.valueof[prop])){

                            
                            //add missing FKs in collection if an array of keys
                            for (var i in this.valueof[prop]){
                                let id = this.valueof[prop][i];
                                if (!Object.hasOwnProperty.call(objectReferences, id)){
                                    if (subType.name === 'ServiceRequestModel'){
                                        // console.log(`MODEL tried to get ${subType.name} `, id, objectReferences);
                                    }
                                        
                                    //have our objectReferences collection prepare a getter for if this record is needed
                                    objectReferences.add(id);
                                    //objectReferences[id];//triggers the fetching
                                }
                            }
                            objectReferences._initialState = true;
                            // for (var j in Object.keys(objectReferences)){
                            //      j = parseInt(j);
                            //      //remove any references that no longer apply
                            //      if (this.valueof[prop].indexOf(j) === -1){
                            //          delete objectReferences[j];
                            //      }
                            // }                        
                        }

                        return objectReferences;
                    },
                    enumerable: true
                });
                

            //FOREIGN MODEL
            //A single reference to a model type class
            } else if (type.prototype instanceof Model){
                store.dispatch('ensure', type);

                Object.defineProperty(this, prop, {
                    
                    set: function(value){
                        this.__validate(this, prop, value);
                        this.valueof[prop] = value;
                    },

                    //Getting related data via foreign key
                    get: function(){

                        if (this.valueof[prop] === null || this.valueof[prop] === undefined){
                            return this.valueof[prop];
                        }

                        //FK
                        if (Number.isFinite(this.valueof[prop])){
                            
                            if (!Object.hasOwnProperty.call(AppData[type.name], this.valueof[prop])){
                                AppData[type.name].add(this.valueof[prop]);
                            }
                            
                            //return a reference to the data property
                            return AppData[type.name][this.valueof[prop]];    
                        
                        } else {
                            return this.valueof[prop];
                        }

                    },
                    enumerable: true
                });

            } else if (Array.isArray(type) && type[0] instanceof Enum){
                

                var enumList = [];
                Object.defineProperty(this, prop, {
                    //Setting Enum as key
                    set: function(value){
                        this.__validate(this, prop, value);
                        enumList.length = 0;
                        if (value !== null){
                            for (var i = 0, l = value.length; i < l; ++i){
                                enumList.push(this.typeof[prop][0][value[i]]);
                            }
                        }
                        this.valueof[prop] = value;
                    },

                    //Getting Enum as value
                    get: function(){
                        if (this.valueof[prop] === null){
                            return this.valueof[prop];
                        } else {
                            return enumList;
                        } 
                    },
                    enumerable: true
                });

            //ENUMS
            } else if (type instanceof Enum){

                Object.defineProperty(this, prop, {
                    //Setting Enum as key
                    set: function(value){
                        this.__validate(this, prop, value);
                        this.valueof[prop] = value;
                    },

                    //Getting Enum as value
                    get: function(){
                        if (this.valueof[prop] === null || this.valueof[prop] === undefined){
                            return null;
                        } else {
                            return this.typeof[prop][this.valueof[prop]];
                        } 
                    },
                    enumerable: true
                });

            } else if (type === Email) {
                console.log("Set up email");
                Object.defineProperty(this, prop, {
                    set: function(value){
                        this.valueof[prop] = value;
                    }, 
                    get: function(){
                        if (this.valueof[prop] === null || this.valueof[prop] === undefined){
                            return null;
                        }
                        
                        return type.display(this.valueof[prop]);
                    },
                    enumerable: true
                });

            } else {

                Object.defineProperty(this, prop, {
                    set: function(value){
                        this.valueof[prop] = value;
                    }, 
                    get: function(){
                        if (this.valueof[prop] === null || this.valueof[prop] === undefined){
                            return null;
                        }
                        
                        return this.valueof[prop];
                    },
                    enumerable: true
                });


                
            }

            // //watch array types for mutations
            // var holdValue = {value: null};
            // Object.defineProperty(this.valueof, prop, {
            //     set: function (value) {
            //         holdValue.value = value;
            //     }, 
            //     get: function () {
            //         return holdValue.value;
            //     },
            //     enumerable: true
            // });
            // if (Array.isArray(type)){
            //     holdValue = reactive({value: []});
            //     watch(
            //         holdValue,
            //         () => {
            //             this.__validate(this, prop, this.valueof[prop]);
            //         }
            //     );
            // }

            //store all the types for later use
            Object.defineProperty(this.typeof, prop, {
                get: () => { return type; }
            });
            Object.defineProperty(this.nullable, prop, {
                get: () => { return nullable || false; }
            });

            if (validation !== undefined && validation !== null){
                //store all the validation objects for later use
                Object.defineProperty(this.__validation, prop, {
                    get: () => { return validation; }
                });
            }
           
        }

    }


        //validates a prop. Does not block setting invalid values
    __validate = function ($this, prop, value) {
            if (!$this._edit){
                return;
            }
            try {
                $this._valid[prop] = this.__isValid($this, prop, value);
                $this._error[prop] = null;

            } catch (e){
                $this._valid[prop] = false;
                $this._error[prop] = e;
            }
        };

    __isValid = function ($this, prop, value) {
        if (value !== null){
            if (Array.isArray($this.typeof[prop])){
                if (value.constructor === Array && value.length){
                    if ($this.typeof[prop][0].name !== $this[prop][value[0]].constructor.name){
                        console.log($this.constructor.name);                            
                    }

                    if ($this.typeof[prop][0].name !== value[0].constructor.name){
                        throw `must be array of type ${$this.typeof[prop][0].name}`;
                    }
                }    

            } else if ($this.typeof[prop].name !== value.constructor.name){
                throw `must be of type ${$this.typeof[prop].constructor.name}`;
            }    
        }

        if ($this.nullable[prop] === false){
            
            if (
                value === null || 
                $this.typeof[prop] === String && value.length === 0
            ){
                throw "is required";
            }

        }

        if (Object.hasOwnProperty.call($this.__validation, prop)){
            
            if (
                Object.hasOwnProperty.call($this.__validation[prop], "length")
                && String(value).length !== $this.__validation[prop].length
            ){
                    throw `must have a length of ${$this.__validation[prop].length}`;
            }

            if (
                Object.hasOwnProperty.call($this.__validation[prop], "minLength")
                && String(value).length < $this.__validation[prop].minLength
            ){
                    throw `has a minimum length of ${$this.__validation[prop].minLength}`;
            }

            if (
                Object.hasOwnProperty.call($this.__validation[prop], "maxLength")
                && String(value).length > $this.__validation[prop].maxLength
            ){
                throw `has a maximum length of ${$this.__validation[prop].maxLength}`;
            }
        }

        //!!Can add regex here

        return true;
    };



}
export default Model;