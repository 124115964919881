<template >
    <header class="header" :class="{'open' : headerOpen || searchOpen, 'search-open': searchOpen, 'login-open': !this.$store.getters.isLoggedIn}">
        <nav class="logo-wrap">
            <router-link to="/" class="logo">
                <img src="@/assets/images/brand/star.svg" alt="Star Leasing" />
            </router-link>
        </nav>
        <div class="m-accordion" :class="{'open': mobileOpen}">
            <div>
                <nav class="main-nav">
                    <search @close-search="searchOpen = false" @open-search="searchOpen = true"></search>
                </nav>
                
                <nav class="main-nav">
                    <router-link to="/reporting"><i class="fas fa-chart-pie"></i><span>Account Overview</span></router-link>
                    <div class="sub-nav">
                        <span><i class="fa-regular fa-truck-moving"></i><span>Fleet</span></span>
                        <div>
                            <router-link to="/fleet"><i class="fas fa-globe-americas"></i><span>Fleet Dashboard</span></router-link>
                            <router-link to="/equipment"><i class="fa-regular fa-truck-moving"></i><span>Equipment</span></router-link>
                            <router-link to="/inspections"><i class="fa-regular fa-chart-simple-horizontal"></i><span>Inspections</span></router-link>
                        </div>
                    </div>
                    <div class="sub-nav">
                        <span><i class="fa-light fa-screwdriver-wrench"></i><span>Service</span></span>
                        <div>
                            <router-link to="/service"><i class="fa-regular fa-square-kanban"></i><span>Service Dashboard</span></router-link>
                            <router-link to="/service-history"><i class="fas fa-history"></i><span>Service History</span></router-link>
                            <router-link to="/work-orders"><i class="fa-regular fa-file-invoice"></i><span>Work Orders</span></router-link>
                            <router-link to="/service/request"><i class="fa-light fa-screwdriver-wrench"></i><span>Request Service</span></router-link>
                        </div>
                    </div>
                    <div class="sub-nav">
                        <span><i class="fa-regular fa-square-dollar"></i><span>Billing</span></span>
                        <div>
                            <router-link to="/billing"><i class="fa-regular fa-credit-card-front"></i><span>Billing Dashboard</span></router-link>
                            <router-link to="/invoices"><i class="fa-regular fa-file-invoice-dollar"></i><span>Invoices</span></router-link>
                            <router-link to="/payments"><i class="fa-regular fa-magnifying-glass-dollar"></i><span>Payment History</span></router-link>
                        </div>
                    </div>
                </nav>

                <nav class="main-nav">
                    
                    <router-link to="/accounts"><i class="fa-regular fa-layer-group"></i><span>Accounts</span></router-link>
                    <router-link to="/users"><i class="fas fa-users"></i><span>Users</span></router-link>
                    
                    <!-- <div class="sub-nav">
                        <router-link to="/equipment/idle"><i class="fa fa-truck-moving"></i><span>Idle Equipment</span></router-link>
                    </div> -->
                </nav>
                <nav class="main-nav">
                    <router-link to="/profile"><i class="fas fa-user"></i><span>Profile</span></router-link>
                    <router-link to="/settings"><i class="fa-regular fa-gear"></i><span>Settings</span></router-link>
                    <a @click="$store.commit('logOut'); $router.push('/users/login')"><i class="fas fa-sign-out-alt"></i><span>Log out</span></a>
                </nav>
                <nav class="main-nav">
                    <a @click="reveal">&nbsp;</a>
                </nav>
            </div>
        </div>
        <a @click.prevent="mobileOpen = !mobileOpen" class="mobile-toggle">
            <span></span>
            <span></span>
            <span></span>
        </a>
    </header>
    <router-view :key="$route.fullPath" v-if="loaded" :class="{ 'detail-open' : this.$route.meta.detailOpen}" />
    <div class="app-loading">
        
    </div>
    
    <transition name="autocordion">
        <div class="autocordion" v-if="viewAs">
            <div>
                <div class="view-as-header"><i class="fas fa-exclamation-circle"></i> You are only viewing data for {{viewAs}}. <a @click.prevent="viewAs = false" class="button"><i class="fas fa-chevron-left"></i>Return to all accounts</a></div>
            </div>
        </div>
    </transition>
    <div class="back-overlay" :class="{open : searchOpen}" @click="searchOpen = false"></div>
    <div class="dynamic-hover" :style="dynamicHoverStyle" v-show="dynamicHoverActive">
        {{dynamicHoverContent}}
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import "./assets/scss/global.scss";


import Search from './components/Search.vue'
import MakeModel from './models/MakeModel';
import ShopLocationModel from './models/ShopLocationModel';
import database from "@/store/database";

export default {
        name: 'App',
        components: { Search },
        setup(){
            const promises = [];
            const loaded = ref(false);
            
            promises.push(database[MakeModel.name].all());
            promises.push(database[ShopLocationModel.name].all());
            
            const shops = database[ShopLocationModel.name].keys;
            const shopData = database[ShopLocationModel.name];

            Promise.all(promises).then(() => {
                loaded.value = true;
            });

            return {loaded, shops, shopData};
        },
        data: function () {
            return {
                appLoaded: false,
                searchOpen: false,
                headerOpen: false,
                mobileOpen: false,
                viewAs: false,
                dynamicHoverLeft: 0,
                dynamicHoverTop: 0,
                dynamicHoverContent: "This is the hover",
                dynamicHoverActive: false,
                lastActiveServiceFlow: null,
            };
        },
        computed: {
            dynamicHoverStyle: function(){
                return "left: 400px;top: 400px;";
            },
        },
    }
</script>

<style lang="scss">
@import "./assets/scss/variables.scss";
.back-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 91;
    opacity: 0;
    right: 100%;
    overflow: hidden;
    transition: $transition-standard;

    &.open{
        right: 0;
        opacity: 1;
    }
} 
.login-open{
    display: none;
}
.view-as-header{
    text-align: center;
    background: nth($yellows, 8);
    color: #000;
    padding: $space-narrow $space-wide;

    a{
        margin-left: $space-standard;
        i{
            margin-right: $space-standard;
        }
    }
}
.dynamic-hover{
    position: fixed;
    background: $white;
    box-shadow: $shadow-standard;
    border-radius: 5px;
    padding: $space-standard $space-wide;
    z-index: 99;
}
</style>
