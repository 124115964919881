import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store.js'

const AccountList = () => import('../views/accounts/AccountList.vue');
const BillingDashboard = () => import('../views/BillingDashboard.vue')
const BulkUpload = () => import('../views/BulkUpload.vue')
// const CallCenterDashboard = () => import('../views/CallCenterDashboard.vue')
// const ContractTemplateList = () => import('../views/contractTemplates/ContractTemplateList')
const EquipmentCreate = () => import('../views/equipment/EquipmentCreate.vue')
const EquipmentDetails = () => import('../views/equipment/EquipmentDetails.vue')
const EquipmentList = () => import('../views/equipment/EquipmentList.vue')
const FleetDashboard = () => import('../views/FleetDashboard3.vue')
const InspectionList = () => import('../views/inspections/InspectionList.vue')
const InvoiceDetails = () => import('../views/invoices/InvoiceDetails.vue')
const InvoiceList = () => import('../views/invoices/InvoiceList.vue')
const PaymentList = () => import('../views/payments/PaymentList.vue')
const RegistrationLookup = () => import('../views/RegistrationLookup.vue')
const ReportingDashboard = () => import('../views/ReportingDashboard.vue')
const ServiceDashboard = () => import('../views/ServiceDashboard.vue')
const ServiceDetails = () => import('../views/service/ServiceDetails.vue')
const ServiceList = () => import('../views/service/ServiceList.vue')
const ServiceRequestFlow = () => import('../views/ServiceRequestFlow2.vue')
// const ShopDashboard = () => import('../views/ShopDashboard.vue')
const StyleGuide = () => import('../views/StyleGuide.vue')
const Test = () => import('../views/Test.vue')
const UserCreate = () => import('../components/users/UserCreate.vue')
const UserDetails = () => import('../views/users/UserDetails.vue')
const UserList = () => import('../views/users/UserList.vue')
const UserLogin = () => import("../views/users/UserLogin.vue")
const UserPasswordReset = () => import("../views/users/UserPasswordReset.vue")
const UserProfile = () => import('../views/users/UserProfile.vue')
const UserRegister = () => import("../views/users/UserRegister.vue")
const WorkOrderDetails = () => import('../views/workOrders/WorkOrderDetails.vue')
const WorkOrderList = () => import('../views/workOrders/WorkOrderList.vue')

const routes = [
    {
        path: '/',
        component: StyleGuide,
        redirect: '/reporting'
    },


    {
        path: '/fleet',
        component: FleetDashboard,        
    },
    {
        path: '/service',
        component: ServiceDashboard,
    },
    {
        path: '/billing',
        component: BillingDashboard,
    },
    {
        path: '/reporting',
        component: ReportingDashboard,
    },
    // {
    //     path: '/shop/:id',
    //     component: ShopDashboard,
    // },
    // {
    //     path: '/sbs',
    //     component: CallCenterDashboard,
    // },



    {
        path: '/styleguide',
        component: StyleGuide,
    },
    {
        path: '/accounts',
        component: AccountList,
    },



    {
        path: '/users',
        component: UserList,
        meta: {
            header : 'All Star Users'
        }

    },
    {
        name: 'UserLogin',
        path: '/users/login',
        component: UserLogin,
        meta: {
            'requiresAuth': false
        }
    },
    {
        name: 'UserResetPassword',
        path: '/users/reset-password',
        component: UserPasswordReset,
        meta: {
            'requiresAuth': false
        }
    },
    {
        name: 'UserRegister',
        path: '/users/register',
        component: UserRegister,
    },
    {
        path: '/logout',
        redirect: '/users/login'
    },
    {
        path: '/users/create', 
        components: {
            'default': UserList,
            'overlay': UserCreate
        },
        meta: {
            'overlay': true
        }
    },
    {
        path: '/users/:id',
        component: UserDetails,
    },
    {
        path: '/profile',
        component: UserProfile
    },

    {
        path: '/inspections',
        component: InspectionList,
        children: [
            {
                path: ':id',
                component: InspectionList
            }
        ]
    },
    {
        name: 'ServiceRequestFlow',
        path: '/service/request',
        component: ServiceRequestFlow,
    },
    {
        name: 'ServiceRequestFlowEquipment',
        path: '/service/request/:id',
        component: ServiceRequestFlow,
    },





    {
        path: '/bulk-upload',
        component: BulkUpload
    },

    {
        path: '/test',
        component: Test
    },
    {
        path: '/registration',
        meta: {
            'login': true
        },
        component: RegistrationLookup 
    
    },


    
    // {
    //     path: '/contract-templates',
    //     component: ContractTemplateList,
    // },

    {
        path: '/equipment/create',
        component: EquipmentCreate,
    },
    {
        path: '/equipment/:id',
        component: EquipmentDetails,
    },
    {
        path: '/equipment',
        component: EquipmentList,
    },

    {
        path: '/invoices/:id',
        component: InvoiceDetails,
    },
    {
        path: '/invoices',
        component: InvoiceList,
    },
    
    {
        path: '/payments',
        component: PaymentList,
    },

    {
        path: '/service-history/:id',
        component: ServiceDetails,
    },
    {
        path: '/service-history',
        component: ServiceList,
    },

    {
        path: '/work-orders/:id',
        component: WorkOrderDetails
    },
    {
        path: '/work-orders',
        component: WorkOrderList
    }
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('checkToken');
    if (to.matched.some(record => record.meta.requiresAuth === false)) {
        //public route
        next(); 
    } else if (!store.getters.isLoggedIn) {
        //private route, not logged in
        next({ name: 'UserLogin' });
    } else {
        //private route, logged in
        //!!CHECK ROLE
        next(); 
    }
});

export default router
