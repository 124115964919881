//Models recognize enum instance, map key to value automagically
//Properties are read-only

class Enum{
    constructor(values){
        var _reverse = {};
        for (var key in values){
            if (Object.prototype.hasOwnProperty.call(values, key)){
                Object.defineProperty(this, key, {
                    value: values[key],
                    enumerable: true
                });
                Object.defineProperty(_reverse, values[key], {
                    value: key,
                    enumerable: true
                })
            }
        } 

        //Weird way to declare a function on a class has a purpose:
        //We need it to not be an enumerable property
        //so when we itterate through the Enum for whatever
        //reason we aren't getting this as a property
        var getName = function(value){
            return _reverse[value];
        };

        Object.defineProperty(this, 'getName', {
            get: function(){
                return getName;
            },
            enumerable: false
        });

        Object.freeze(this);
    }
}

export default Enum;