import Model from './Model.js';


class VendorModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);
        this.prop('name', String, true);

        this.populate(values, config);

    }
    static name = 'VendorModel';
    static source = 'Vendor';
}
window[Symbol.for(VendorModel.name)] = VendorModel;

export default VendorModel;