import { reactive, ref, toRaw, watch } from "vue";
import store from "../store/store";
import Model from "./Model";
import database from "@/store/database";

//!! This entire class may be redundant
class Collection extends Object{
    constructor(ofModel, settings={}){
        super();
        settings;

        
        Object.defineProperty(this, 'typeof', {
            get: function(){
                return ofModel;
            },
            enumerable: false
        });
        
        Object.defineProperty(this, 'populate', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'prepare', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'add', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'delete', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'ready', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'where', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'forEach', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'select', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'sort', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'first', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'last', {
            writable: true,
            enumerable: false
        });
        Object.defineProperty(this, 'has', {
            writable: true,
            enumerable: false
        });
        this.has = function(id){
            return Object.hasOwnProperty.call(this, id);
        }

        //triggers the fetch of all records on this collection, returns promise
        Object.defineProperty(this, 'load', {
            writable: true,
            enumerable: false
        });
        this.load = function(some){
            let promises = [];
            if (some !== undefined){
                for (let i in some){
                    promises.push(this[some[i]]._loader);
                }
            } else {
                for (let i in this){
                    promises.push(this[i]._loader);
                }
            }
            return Promise.all(promises);
        }

        // Object.defineProperty(this, 'status', {
        //     enumerable: false,
        //     get: () => {
        //         return status;
        //     }
        // });

        const status = reactive({
            length: ref(0),
            loadedRecords: ref(0),
            initialState: false,
            loaded: ref(false)
        });

        //initial state, prevents 0 records === 0 loaded returning true
        //but allows us to flag if this collection loaded empty
        Object.defineProperty(this, '_initialState', {
            enumerable: false,
            set: (value) => {
                status.initialState = value;
            }, 
            get: () => {
                return status.initialState;
            }
        });

        //dynamic length
        Object.defineProperty(this, 'length', {
            enumerable: false,
            get: () => {
                status.length = Object.keys(this).length;
                return status.length;
            }
        });


        //set up a promise that can be subscribed to
        //with an external resolver
        var resolver;
        var loaderPromise = new Promise((resolve) => {
            resolver = resolve;
        }); 
        Object.defineProperty(this, '_loader', {
            enumerable: false,
            get: () => {
                return loaderPromise;
            }
        });
        

        //loaded var
        this._initialState = ref(false);
        Object.defineProperty(this, '_loaded', {
            enumerable: false,
            get: () => {
                return status.loaded;
            }
        });

        watch([status], () => {
            
            if (status.initialState === true && status.length === status.loadedRecords){
                status.loaded = true;
                resolver();
            } else {
                status.loaded = false;
            }
            if (keys.length !== Object.keys(this).length){
                keys.length = 0;
                keys.push(...Object.keys(this));
                
            }
        }, {deep: true});

        const keys = reactive([]);
        Object.defineProperty(this, 'keys', {
            enumerable: false,
            get: () => {
                return keys;
            },
        });

        //set up getter at ID to lazy load a single record
        this.add = function (id, payload) {
            if (Object.keys(this).indexOf(id) !== -1){
                console.log("Avoided re-adding (shouldn't get to this point)");
                return;
            }
            status.length++//!!MAJOR CHEAT

            //if we have the payload, set this up now
            //basically getAll, other chunk calls that aren't asking for specific Ids 
            if (payload !== undefined){
                
                //this happens when a local collection sets the reference to the AppData
                //because the proxy setter send it to the add function 
                if (payload instanceof Model){
                    this[id] = payload;
                    return;
                }

                //set up the record
                Object.defineProperty(this, id, {
                    enumerable: true,
                    configurable: true, 
                    writable: true,
                });
                this[id] = new ofModel(payload);

                //track loading of model (usually immediate)
                this[id]._loader.then((response) => {
                    status.loadedRecords++;
                    return response;
                });
                status.initialState = true;
                return;
            }

            //we don't have the payload, set up a self-deleting getter for lazy load
            Object.defineProperty(this, id, {
                enumerable: true,
                configurable: true,
                set: (newValue) => {
                    delete toRaw(this)[id];
                    this[id] = newValue;
                },
                get: () => {

                    //we don't need this logic after the first get
                    delete toRaw(this)[id];

                    //pointer to root app data
                    this[id] = database[ofModel.name][id];
                    
                    //count number of loaded props
                    //this avoids needing to get the records to check collection loaded state
                    store.state.AppData[ofModel.name][id]._loader.then((response) => {
                        status.loadedRecords++;
                        return response;
                    });

                    //return the AppData reference
                    return this[id];
                }
            });

            status.initialState = true;
        }
        




        this.populate = function(records){
            for (var i in records){
                if (Object.hasOwnProperty.call(records, i)){
                    let id = records[i].id || i;
                    if (Object.hasOwnProperty.call(this, id)){
                        //!! There should always be an id for the record
                        //!! Holdover from meh GPS data
                        //if this ID already exists locally in this collection, update it
                        this[id].populate(records[i]);

                    } else if (records[i] instanceof Model){
                        //we are populating with an object/array full of models
                        this[id] = records[i];
                        status.length++;
                        status.loadedRecords++;
                        
                    } else {
                        //will set up model/pointer if not already in this collection
                        this.add(id, records[i]);
                    }
                } else {
                    console.log("!!Never actually happens, seems redundant");
                }
            }
            status.initialState = true;
            return this;
        }
        

        this.delete = function(id){
            delete this[id];
            status.length--;
            // status.loadedRecords--;
        }




        /**UTILITY METHODS*/
        //filter a collection with a test function
        this.where = function (test) {
            var d = Object.fromEntries(Object.entries(this).filter((x, i) => {
                //obj, id, index
                return test(x[1], x[0], i);
            }));
            console.log(`'where' created a collection for ${ofModel.name}`);
            return new Collection(ofModel).populate(d);
        }
        //filter a collection with a test function
        this.forEach = function (test) {
            var d = Object.entries(this);
            if (d){
                d.forEach((x, i) => {
                    //obj, id, index
                    test(x[1], x[0], i);
                });    
            }
            return this;
        }
        //select properties
        this.select = function (test) {
            let build = [];
            for (var i in this){
                build.push(test(this[i]));
            }
            if (build[0] instanceof Model){
                console.log("Select created a collection");
                return new Collection(build[0].constructor).populate(build);
            }
            return build;
        }
        //sort a collection with a test function
        this.sort = function (test) {
            //!*THIS LOSES ID->RECORD MATCHIN*!
            //!*OBJECTS CANNOT BE SORTED EXCEPT BY KEY ORDER*!
            let entries = Object.values(this);
            entries.sort((x, y, i) => {
                //obj1, obj2, index
                return test(x, y, i);
            });
            //!*Populating a new collection would use record ID not index*!
            return entries;
        }
        this.first = function () {
            return this[Object.keys(this)[0]];
        }
        this.last = function () {
            return this[Object.keys(this).pop()];
        }
        
        const $this = new Proxy(this, {
            set: (target, key, value) => {
                if (
                    ofModel.source !== undefined
                    && (
                        !Object.hasOwnProperty.call(this, key) && typeof key === 'string' && key.indexOf('_') === -1 
                        || !Object.hasOwnProperty.call(this, key) && typeof key !== 'symbol' && !isNaN(key)
                    )
                ){
                    if (ofModel.name === 'PaymentAllocationCreateDTO'){
                        console.log("Setted", key);
                    }
                    this.add(key, value);
                } else {
                    this[key] = value;
                }
                return true;
            }, 
            get: (target, key) => {
                if (key === 'toJSON'){
                    return target[key];
                }
                if (
                    ofModel.source !== undefined
                    && (
                        !Object.hasOwnProperty.call(this, key) && typeof key === 'string' && key.indexOf('_') === -1 
                        || !Object.hasOwnProperty.call(this, key) && typeof key !== 'symbol' && !isNaN(key)
                    )
                ){
                    this.add(key);
                }
                return target[key];
            },
            deleteProperty: (target, key) => {
                if (Object.hasOwnProperty.call(this, key) && typeof key === 'string' && key.indexOf('_') === -1 
                    || Object.hasOwnProperty.call(this, key) && typeof key !== 'symbol' && !isNaN(key)
                ){
                    this.delete(key);
                } else {
                    delete target[key];
                }
                
                return true;
            }
        });

        return $this;
    }
}

export default Collection;